import React, { useState, useEffect } from 'react';
import { Link } from "gatsby"

import client from '../api/contentful'
    
const LatestUpdate = () => {
    const[content, setContent] = useState()

    useEffect(() => {
        client.getEntries({
            content_type: 'update'
        })
        .then(entry => setContent(entry.items[0]))
        .catch(err => console.log(err));
    
      }, [])

    if(!content) return null

    const dateOptions = {year: 'numeric', month: 'long', day: 'numeric' };
    let formatDate = Date.parse(content.fields.date)
    formatDate = new Intl.DateTimeFormat('en-US', dateOptions).format(formatDate)

    return (
        <section className="cta-twocolumn">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <p className="cta-twocolumn-category">Latest update</p>
                        <h2 className="cta-twocolumn-title">{content.fields.title}</h2>
                        <p>{formatDate}</p>
                        <div className="uta-btn-group">
                            <Link to={`/updates/${content.fields.slug}`} role="button" className="uta-btn uta-btn-compact-primary"><span>Read the update</span></Link>
                            <Link to={`/updates`} role="button" className="uta-btn uta-btn-compact-secondary"><span>All updates</span></Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LatestUpdate
