import React, { useState, useEffect } from "react"

import Layout from "../components/layout"
import Container from "../components/Container"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import VerticalListing from "../components/VerticalListing"
import LatestUpdate from "../components/LatestUpdate"

import client from '../api/contentful'


const IndexPage = () => {
  const[content, setContent] = useState()
  let list;

  useEffect(() => {
    client.getEntries({
        content_type: 'landing'
    })
    .then(entry => setContent(entry.items[0]))
    .catch(err => console.log(err));

  }, [])

  // console.log(content)

  if(content) {
    list = content.fields.featuredLinks.map((item, index) => {
      return ({
        name: item.fields.sectionTitle,
        description: item.fields.sectionDescription,
        image: {
          src: 'https:'+item.fields.sectionImage.fields.file.url,
          alt: item.fields.sectionImage.fields.description
        },
        path: item.fields.path,
        linkText: 'Learn More'
      })
    })
  } 
  

  return (
    <Layout>
      <SEO title="Home" />
      {content &&
        <Container>
        <section className="page-content col">
          <div className="row">
              <Hero title={content.fields.headline} description={content.fields.description}/>
            
              <div className="col-lg-9  mx-auto">
                <LatestUpdate />
                <VerticalListing list={list} />
              </div>
          </div>
        </section>
        </Container>
      }
    </Layout>
  )
}

export default IndexPage
